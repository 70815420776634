@import "../../../assets/sass/index";

.SpinnerArea {
  width: 100%;
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0;
  align-items: center;
  @media screen and (min-width: 320px) {
    margin-top: 50%;
  }
  @media screen and (min-width: 992px) {
    margin-top: 20%;
  }
}
.Center {
  margin: auto;
}
.Small {
  margin: auto;
  min-height: 35px;
  height: 35px;
  width: 35px;
  min-width: 35px;
  .Spinner {
    min-height: 30px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    font-size: 10px;
  }
}
.Spinner,
.Spinner:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.Spinner {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  max-width: 150px;
  max-height: 150px;
}