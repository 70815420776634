@import "./assets/sass/index";
@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("./assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf");
  // src: url("./assets/fonts/montserrat/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  font-style: italic;
  src: url("./assets/fonts/montserrat/Montserrat-BoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("./assets/fonts/montserrat/Montserrat-ExtraBold.ttf");
}

// Font Famylly lato
@font-face {
  font-family: "Lato";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/lato/Lato-Bold.ttf");
}

/**
 * Global
 */
html {
  scroll-behavior: smooth;
}
body {
  background-color: $grayLighter;
  color: $grayDark;
  font-size: $initialFontSize;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3rem;
}

.divisor {
  margin: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: $secondaryColorDarker;
}

a:hover {
  color: $secondaryColorDarker;
}

img {
  width: 100%;
  height: auto;
}

p {
  font-size: 1em;
  line-height: 1.2em;
  color: $textColor;
}
a {
  display: inline-block;
  text-decoration: none;
  @include getLinkAHF($primaryColorDarker);
}
[tabIndex] {
  cursor: pointer;
}

.App {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-color: $grayLighter;
  background-blend-mode: multiply;
}

h2 {
  font-size: 0.9em;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-vert {
  display: flex;
  flex-direction: column;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
										supported by Chrome, Edge, Opera and Firefox */
}

input[type="password"],
input[type="email"],
input[type="text"] {
  -webkit-user-select: text;
  user-select: text;
}
.text-center {
  text-align: center;
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  height: 20px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #662D91;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  height: 20px;
  padding: 3px;
}

input[type="radio"]:focus-within {
  // background-color: $primaryColorDarker;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  height: 20px;
  padding: 3px;
}
.mb {
  margin-bottom: 15px;
}
.mt {
  margin-top: 15px;
}
.ml {
  margin-left: 15px;
}
.mr {
  margin-right: 15px;
}
.LinkBtn {
  @include getButton($white, $secondaryColor, $secondaryColorDark);
  padding: 5px 15px;
}
.LinkBtnPlus {
  @include getButton($white, $primaryColorAudio, $primaryColorDarker);
  padding: 5px 15px;
  margin: 0 15px;
}

iframe{
  pointer-events: none;
}