// TO CONTROL PADDINGS AND POSITIONS.
$TopNavHeight: 80px;
$mainTitle: 140px;
$mainTitle: 150px;
// TO CONTROL ITS POSITION TO LEFT.
$widthAsideNav: 250px;
$widthAsideNavSm: 80px;
$widthAsideNavXs: 60px;

// To control initial font size.
$initialFontSize: 16px;

// BASE COLORS
$primaryColor: #1ab9de;
$primaryColorDarker: #0d90ad;
$primaryColorAudio: #007085;
$secondaryColorLighter: #93278f;
$secondaryColorLight: #6d1b6d;
$secondaryColor: #662d91;
$secondaryColorDark: #3c195b;
$secondaryColorDarker: #3b184f;

$white: #fdfdfd;
$whiteDark: #f0f0f0;
$kcolorfontprimary: #333333;
$kcolorfontsubtitle: #4d4d4d;
$kcolorfontsecondary: #666666;
$kcolorfontthird: #999999;
$kcolorfont4: #bebebe;

// Gray
$grayLighter: #ffffff;
$grayLight: #cccccc;
$graySemiLight: #999999;
$gray: #666666;
$grayDark: #4d4d4d;
$grayDarker: #333333;

//Colors
$green: #009245;
$greenLight: #8cc63f;
$yellow: #ffca55;
$orange: #ffb43c;
$orangeDark: #f15a24;
$red: #c1272d;

$outline: #5771f3;
// outline
// Setted Colors
$bgLabel: $primaryColor;
// $outline: rgba($secondaryColor, 0.8);
// Text
$textTitleColor: $grayDarker;
$textSubTitleColor: $grayDark;
$textColor: $gray;
$textColorPrimary: $primaryColor;
$textColorSecondary: $secondaryColor;
$textColorSecondaryDarker: $secondaryColorDarker;
// Buttons
$btnPrimary: $primaryColor;
$btnPrimaryHover: $primaryColorDarker;
$btnSecondary: $secondaryColor;
$btnSecondaryHover: darken($secondaryColor, 10%);
$btnSecondaryDarker: $secondaryColorDarker;
$btnSecondaryDarkerHover: darken($secondaryColorDarker, 10%);
$btnGray: $grayLighter;
$btnGrayHover: darken($grayLighter, 10%);
$btnTranspHover: rgba($white, 0.1);
// levels bar
$levelBar: $gray;
$level01: $green;
$level02: $greenLight;
$level03: $orange;
$level04: $orangeDark;
$level05: $red;

// paddings
$padding: 15px;

// Shadowns
$boxDhadow: 0px 0px 10px 1px rgba($grayLight, 0.5);
$boxDhadowFocus: 0px 0px 3px 2px rgba($outline, 0.7);
$boxShadownCards: 0 0 7px 5px rgba(0, 0, 0, 0.05);

// Z-INDEX
$zindexTopNav: 9999;
$zindexModal: 9995;
$zindexAsideBtn: 999;
$zindexAside: 990;
$zindexMaitTitle: 99;
$topNavHeight: 80px;
