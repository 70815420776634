@import "../../assets/sass/index";
.footerStyle {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 16px;
  letter-spacing: 1px;
  background-color: #ebebeb;
  line-height: 16px;
  font-size: 10px;

  position:relative;//
  bottom: 0 !important;//
  width: 100%;//
  left: 0;//

  z-index: 1000;

  @media(max-width: 992px){
    //margin-bottom: 90px;
    z-index: 1;
    padding-bottom: 140px;
  }

  @media(max-width: 768px){
    padding-bottom: 180px;
  }
}
.footerSpace {
  padding: 5px 15px;
}

.ButtonHelp:hover{
  color: #fff !important;
}
.ButtonHelp {
  z-index: 1100;
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 10px;
  border-radius: 100%;
  background-color: #009bb8;
  color: #f6f6f6;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 30px;
  padding: 0;
  margin: 0;
  transition: 0.5s;
  cursor: pointer;
  svg{
    margin-right: 0px;
  }
  @media screen and (max-width: 992px) {
    bottom: 160px;
  }
  @media screen and (max-width: 800px) {
    bottom: 180px;
  }
  @media screen and (max-width: 768px) {
    bottom: 160px;
  }
  @media screen and (max-width: 400px) {
    bottom: 120px;
  }

}
.ButtonHelp .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 0.5em;
  position: absolute;
  z-index: 999;
  left: 80%;
  margin-left: -170px;
  opacity: 0;
  transition: opacity 0.3s;
}

.ButtonHelp:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
