@import "../../assets/sass//index";

.Avatar {
  box-sizing: border-box;
  min-height: 60px;
  height: 60px;
  min-width: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: $secondaryColor;
  margin-right: 10px;
  padding: 3px;
  position: relative;
  &.NoMargin {
    margin-right: 0;
  }
  &.MoreMargin {
    margin-right: 20px;
  }
  .Letters {
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .Img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
    background: $whiteDark;
    filter: brightness(1.3);
  }
}
