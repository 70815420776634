@import "../../assets/sass/index";

.Aside {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $btnGrayHover;
  width: 100%;
  z-index: 999999 !important ;
  @media (min-width: 992px) {
    margin-top: $topNavHeight;
    left: 0;
    top: 0;
    max-width: $widthAsideNav;
    max-width: $widthAsideNav;
    min-height: 100vh;
    width: 100%;
    max-width: $widthAsideNav;
    z-index: 999 !important;
  }
}
.Nav {
  display: flex;

  @media (min-width: 992px) {
    display: block;
  }
  a{
    position: relative;
    border-bottom: 2px solid #fff;
    padding: 15px 0 15px 25px;
    display: table;
    font-size: 13px;
    font-weight: 400;

    @media(max-width: 922px){
      border: none;
      padding-left: 0;
      display: flex;
      position: static;
    }
    &:first-child{
      border-top: 2px solid #fff;  

      @media(max-width: 992px){
        border: none;
      }
    }
  }
  span{
    .trianguloEsquerda{
      position: absolute;
      width: 15px;
      left: 0;
      top: 0;
      height: 100%;
      margin-right: 10px;
      fill: #e6e6e6;

      @media(max-width:992px){
        display: none;
      }
    }

    .trianguloDireita{
      position: absolute;
      width: 12px;
      right: 0;
      top: 0;
      height: 100%;
      fill: #e6e6e6;

      @media(max-width:992px){
        display: none;
      }
    }
  }
}

.Link {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;
  color: $secondaryColorLight;
  font-weight: bold;
  font-size: 0.8em;
  text-align: center;
  border-left: none;
  //border-bottom: 5px solid $btnGrayHover;
  padding: 10px 0px 10px 0;
  text-transform: uppercase;


  @media (min-width: 992px) {
    font-size: 0.8em;
    display: block;
    background-color: $btnGrayHover;
    text-align: left;
    border-bottom: none;
    text-transform: uppercase;
    padding: 10px 0px 10px 5px;
  }
  // @include getButtonAFH($grayDarker, $white, red);

  &.Active {
    //border: none;
    box-shadow: none;
    border-color: #fff !important;
  
    // border-right: 25px solid #aba8b2;

    span{
      .trianguloEsquerda{
        fill: #662d91 !important;
      }
      .trianguloDireita{
        fill: #fff !important;
      }
    }
    border-bottom: 2px solid #fff !important;
    background-color: $grayLight;
    @include getButtonAFH(
      $secondaryColorLight,
      $btnGrayHover,
      $secondaryColorLight
    );
    margin: none;
  }
  span {
    margin-top: 5px;
  }
  @media screen and (max-width: 400px) {
    span {
      display: none;
    }
    .Icon {
      font-size: 1.5em;
    }
  }
}
.Link:hover {
  //border: none;
  box-shadow: none;
  color: $secondaryColorLight;
  background-color: $grayLight;
  border-color: #fff !important;

  span{
    .trianguloEsquerda, .trianguloDireita{
      fill: #cccccc;
    }
  }
}
.Icon {
  margin-right: 0;
  @media screen and (min-width: 992px) {
    margin-right: 5px;
  }
}

.TitleAside {
  text-align: left;
  font-size: 0.9em;
  width: 15em;
  padding: 15px 0 15px 25px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}


.botoes{

  width: 100%;
  margin:  0 15px;

  @media(max-width:992px){
    display: none;
  }

  .buttonA{
    width: 90%;
    height: 40px;
    //margin: 0 auto;
    border: 1px solid #662d91 !important;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    padding: 0px;
    cursor: pointer;
  
    img{
      width: 120px;
    }
    @media(max-width: 992px){
      display: none;
    }
  }
}

